import React, { useState, useEffect, useContext, useRef } from "react";
import ReactDOM from "react-dom";
import utilities from "./UtilityFunctions"
import OtherExpenses from "./OtherExpenses";
import Comments from "./Comments";
import { GlobalContext } from "./Cars";
import fields from "./fields";
import AddEditCustomer from "./AddEditCustomer";
import AddEditBroker from "./AddEditBroker";
import CarPayments from "./CarPayments";
import UtilityFunctions from "./UtilityFunctions";
import AddEditSeller from "./AddEditSeller";
import { BrokersCustomersSellers } from "./SubNav";

function AddEditCommitted({ car, showModal, formMode, parentSheetName, origin }){
    //if(!car) showModal({visible: false});
    const globals = useContext(GlobalContext);
    const { socket, vatRate, msgBoxStatus, toggleMessageBox, getMsgBoxResult, asyncOpenTextBox } = globals;
    const {sellers, brokers, customers} = useContext(BrokersCustomersSellers);
    const [ showAddSellers, setShowAddSellers ] = useState({
        visible: false,
        formMode: "add"
    });

    const [ showAddBrokers, setShowAddBrokers ] = useState({
        visible: false,
        formMode: "add"
    });
    
    const [ carFallBack, setCarFallback ] = useState((()=>{
        //Cast all supposed-to-be-numbers from strings
        Object.keys(car).forEach((property)=>{
            if(fields[property].dataType === "integer" || fields[property].dataType === "decimal"){
                car[property] = Number(car[property])
            }
        })   
        //Add the fields for TotalDemurrage and TotalCostWithDuty
        //They're computed by qryCommitted, for efficiency. Leaving that to
        //The client side
        return {...car, TotalDemurrage: 0, TotalCostWithDuty: 0, 
            ReleaseBalance: 0, CreditAmount: 0, MonthlyPayment: 0, 
            DeliveryDate: car.DeliveryDate || "" /*utilities.formatFromInput((new Date()).toISOString(), "date")*/,
            SalesInvoiceNo: car.SalesInvoiceNo || ""
        };
            })());
    const [ carData, setCarData ] = useState(carFallBack);
    const [ activeTab, setActiveTab ] = useState(1);

    const pendingMessages = useRef([]);
    const table_name = "Sales";
    const primary_key = "VehicleID";

//    const inputClassList = "form-control";
//    const inputWrongClassList = "form-control form-control-wrong";

    //Calculate the values in the calculated fields
    formMode==="edit" && Object.keys(carData).forEach((key, index, arr)=>{
        
        switch(key){
            case "CifMombasa":
                carData[key] = carData.PurchasePrice + carData.ShippingCost;
                break;
            case "Days":
                carData[key] = utilities.getDaysDifference(carData.WarehouseDate, Date());
                break;
            case "TotalDemurrage":
                //console.log(`Days: ${carData.Days}`)
                carData[key] = carData.Days * carData.DailyDemurrage * vatRate;
                break;
            case "TotalCost":
                //carData.ClearingCostUG + carData.TotalDemurrage + carData.OtherExpenses + carData.TargetCommission
                carData[key] = (carData.CifMombasa + carData.LandTranCost + carData.ClearingCost) * carData.ExchangeRate + carData.ClearingUG + carData.TotalDemurrage + carData.OtherExpenses + carData.TargetCommission;
                break;
            case "TotalCostWithDuty":
                carData[key] = carData.TotalCost + carData.Duty;
                break;
            default:
        }
    });
    const { sendOrQueue } = utilities;

    const replaceRecordListener = (msg)=>{
        if(msg.sheetName!==parentSheetName) return; //Different sheet
        if(carData[msg.primaryKeyName]!==msg.primaryKeyValue) return; //Primary keys don't match
        const car = msg.parentRecord;
        setCarData(prev=>{
            Object.keys(car).forEach((property)=>{
                if(fields[property].dataType === "integer" || fields[property].dataType === "decimal"){
                    car[property] = Number(car[property])
                }
                if(fields[property].dataType === "date"){
                    car[property] = UtilityFunctions.formatIntoInput(car[property],"date");
                }
            })   
            return {...car, TotalDemurrage: 0, TotalCostWithDuty: 0};
        });
    }

    const fieldUpdateListener = (msg)=>{
        if(msg.sheetName!==parentSheetName){
            //Only handle events for this sheet
            return;
          }
        //Get the Field and Status {ok: true/false, fieldName: fieldName}
        if(msg.ok){ //Update was successful
            //Update the fallback
            setCarFallback({...carFallBack, [msg.fieldName]: carData[msg.fieldName]});
            
        } else { //Check and log msg.error
            //Show error and revert to fallback
            setCarData({...carData, [msg.fieldName]:carFallBack[msg.fieldName]});
        }
    }
    const ioReconnectListener = ()=>{
        let attempts = 10;
        let currMsg = {};
        //Check the queue for any messages for transmission
        while(pendingMessages.current.length>0) {
            console.log(`${pendingMessages.current.length} messages in queue`);
            //Limit no. of attempts to 100 to prevent endless loop
            currMsg = pendingMessages.current.shift();
            sendOrQueue(currMsg, socket, pendingMessages.current, carData, carFallBack, setCarData);
            if(attempts===0){
                break;
            }
            attempts--;
        }
    }
    useEffect(()=>{
        console.log("Car: ", car);
        //Add event listeners to the websocket
        //Return a function to remove them when component dismounts
        if(socket.disconnected){
            socket.connect()
        }
        socket.on('replace_record', replaceRecordListener);
        socket.on('update_record', fieldUpdateListener);
        socket.on('connect',ioReconnectListener);

        return ()=>{
            socket.off('replace_record', replaceRecordListener);
            socket.off('update_record', fieldUpdateListener);
            socket.off('connect',ioReconnectListener);
        }
    },[]);
    const handleChange = ({ target })=>{
        //console.log(`Input name: ${target.value}`);
        //const regexx = fields[target.name].regex || null
        const newVal = utilities.formatFromInput(target.value, fields[target.name].dataType);
        setCarData(prev=>{
            return {...prev, [target.name]: newVal}
        });
    }
    const handleBlur = ({ target })=>{
        //Do the RegExp check here
        const regexx = fields[target.name].regex || null
        const newVal = utilities.formatFromInput(target.value, fields[target.name].dataType);
        let prevVal = utilities.formatFromInput(carFallBack[target.name], fields[target.name].dataType);
        if(newVal===prevVal) return;

        if(regexx && !(new RegExp(regexx)).test(newVal)){ 
            //If it fails the regex test
            //Show an error and revert to the backup value
            //Else, set the value and backup value. Or not since it
            //May undo a lot of laboured typing
            toggleMessageBox({
                on: true,
                title: "Invalid Value",
                message: "Invalid value for "+fields[target.name].displayName,
                buttons: ["OK"]
            });
            document.getElementById(target.name).focus();
            return;
        } else {

        }
        /*compare car and carData. If there's a change,
        send update the database (in edit mode)
        Send DealershipID, UserID, data
        Optimistically update UI (Default. Do nothing)
        When async response returns, either keep update
        Or revert to car */
        //Okay, here we go once again. Some really long text to see if the checks on the regex are actually working, so I have to type some really long text to test it out. Wonder if this is long enough. Only one way to find out.
        if(formMode==="edit") {
            //console.log(`New val: ${newVal}, Prev val: ${prevVal}`);
            if(!(new RegExp(fields[target.name].regex)).test(newVal)){
                console.log("Invalid value for "+fields[target.name].displayName);
                return;
            }

            if(newVal!=prevVal) { //It has been changed, and passes the regex check.
                const msg = {
                    DealershipID: carData["DealershipID"],
                    UserID: "TODO",
                    ownSheetName: parentSheetName,
                    table: table_name,
                    primaryKeyName: primary_key,
                    primaryKeyValue: carData.VehicleID, //This way, I update the records
                    fieldName: target.name, //With "StatusFlag='Cancelled'" too
                    fieldValue: newVal,
                    triggerRefresh: fields[target.name].triggerRefresh
                };

                sendOrQueue(msg, socket, pendingMessages.current, carData, carFallBack, setCarData);

            } //Else, nothing changed, ignore
        } //Else, ignore. We don't have a vehicle ID yet. Form will be POSTed
    }

    const checkBeforeClose = ()=>{
        // Do the checks and submit, then close and refresh
        let proceed = !Object.keys(carData).some((propertyName) => {
            //console.log(propertyName)
            if ((fields[propertyName]?.regex && !(new RegExp(fields[propertyName]?.regex)).test(carData[propertyName]) || (fields[propertyName].required && (carData[propertyName]==='' || carData[propertyName]===null)))) {
                // Regex exists and fails.  
                toggleMessageBox({
                    on: true,
                    title: "Input Error",
                    message: `Invalid or empty value for required field ${fields[propertyName].displayName}: ${carData[propertyName]}`,
                    buttons: ["OK"],
                });
                // TODO: Go to the tab and focus on the element
                return true;
            } else {
                return false;
            }
        });

        //Check that if a purchase broker is selected, commission should be >1
        if(carData.PurchaseBrokerID>1 && !carData.PurchaseCommission){
            toggleMessageBox({
                on: true,
                title: "Input Error",
                message: `If you select a broker, you must add commission`,
                buttons: ["OK"],
            });
            proceed = false;
        }
        //If commission is added, a purchase broker must be selected
        if(carData.PurchaseBrokerID===1 && carData.PurchaseCommission){
            toggleMessageBox({
                on: true,
                title: "Input Error",
                message: `If you add commission, you must select a broker`,
                buttons: ["OK"],
            });
            proceed = false;
        }

        //Check that if a sales broker is selected, commission should be >1
        if(carData.BrokerID>1 && !carData.AgreedCommission){
            toggleMessageBox({
                on: true,
                title: "Input Error",
                message: `If you select a broker, you must add commission`,
                buttons: ["OK"],
            });
            proceed = false;
        }
        //If commission is added, a sales broker must be selected
        if(carData.BrokerID===1 && carData.AgreedCommission){
            toggleMessageBox({
                on: true,
                title: "Input Error",
                message: `If you add commission, you must select a broker`,
                buttons: ["OK"],
            });
            proceed = false;
        }

        if(carData.Origin==="Local" && carData.SellerID===1){
            //A seller must be selected for Local purchases
            toggleMessageBox({
                on: true,
                title: "Input Error",
                message: `Please select or add the seller`,
                buttons: ["OK"],
            });
            proceed = false;
        }

        return proceed;
    }

    const handleCurrencyBlur = ({ target })=>{ //For PurchaseCurrency
        //If value is UGX, set exchange rate to 1 and make it immutable

        handleBlur({ target });
        //Get the exchange rate element
        const exchangeRateElement = document.getElementById("ExchangeRate")
        if(target.value==="UGX"){            
            //Submit 1 as the exchange rate
            setCarData(prev=>{
                return {...prev, "ExchangeRate": 1}
            })
            exchangeRateElement.disabled = true;

            //Submit the new Exchange rate to the db
            if(formMode==="edit") {
                //If it's a number, remove commas
                let newVal = 1; //If changed to UGX, new value is 1
                let prevVal = utilities.formatFromInput(carFallBack["ExchangeRate"], fields["ExchangeRate"].dataType);
                
                //console.log(`New val: ${newVal}, Prev val: ${prevVal}`);
                if(!(new RegExp(fields["ExchangeRate"].regex)).test(newVal)){
                    console.log("Invalid value for "+fields["ExchangeRate"].displayName);
                    return;
                }
    
                if(newVal!=prevVal) { //It has been changed, and passes the regex check.
                    const msg = {
                        DealershipID: carData["DealershipID"],
                        UserID: "TODO",
                        ownSheetName: parentSheetName,
                        table: table_name,
                        primaryKeyName: primary_key,
                        primaryKeyValue: carData.VehicleID,
                        fieldName: "ExchangeRate",
                        fieldValue: newVal,
                        triggerRefresh: fields["ExchangeRate"].triggerRefresh
                    };
    
                    sendOrQueue(msg, socket, pendingMessages.current, carData, carFallBack, setCarData);
    
                } //Else, nothing changed, ignore
            } //Else, ignore. We don't have a vehicle ID yet. Form will be POSTed
        } else { //Then user will put a custom value to trigger onBlur
            exchangeRateElement.disabled = false;
        }
    }

    const handleClose = ()=>{
        if(!checkBeforeClose()) return;
        showModal({visible: false});
    }    


    const formTabsClasses = "btn formtab-btn";
    const formTabsActiveClasses = "btn formtab-btn formtab-btn-active";
    const cancelDeal = async ()=>{
        const msgBoxResult = await getMsgBoxResult({
            on: true,
            title: "Confirm",
            message: "Are you sure you want to cancel this deal?",
            buttons: ["YES", "NO"],
        });

        if(msgBoxResult==="NO"){
            return;
        }

        const cancelReason = await asyncOpenTextBox({
            on: true,
            title: "Please provide a reason for cancellation",
        });

        if(cancelReason==="CANCEL"){
            return;
        }

        fetch("https://api.autodealerug.com/canceldeal", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                DealershipID: globals.DealershipID,
                DealAgreementNo: carData.DealAgreementNo,
                CancelReason: cancelReason,
                UserID: globals.userData.UserID,
                CancelDate: (new Date()).toISOString(),
                ownSheetName: parentSheetName
            })
        }).then( async (res)=>{
            if(res.ok){ //200. Close the modal, locally delete the car?
                //Or wait for the "recordupdate" event and handle it there
                const returnObj = await res.json();
                console.log("Cancellation OK. Rows Affected: "+returnObj.data);
                showModal({
                    visible: false,
                    mode: ""
                });
                
            } else { //400, 500. Server issue. Check errors.
                toggleMessageBox({            
                    on: true,
                    title: "Server connection error",
                    message: "Error:" +res.statusText,
                    buttons: ["OK"],
                    clicked: ""});
            }
        }).catch((err)=>{ //Possible connectivity issue
            console.log(`Network error: ${err.message}`);
            toggleMessageBox({
                on: true,
                title: "Network Error",
                message: "Please check your network and try again",
                buttons: ["OK"],
                clicked: ""
            })
        });
    }

    const handleSubmit = async (e)=>{
        e.preventDefault();
        //In add mode, send a fetch request
        //In edit mode, use websockets. Change button to "Save And Close"
            //First check that the required fields were filled
        for(let [key, val] of Object.entries(carData)){
            //Check required fields have been entered
            //console.log(key);
            if(fields[key]?.required){
                //console.log(`${key} = ${val}`)
                if(fields[key].required  && val===""){
                    toggleMessageBox({
                        on: true,
                        title: "Error",
                        message: `${fields[key].displayName} can not be empty: ${val}`,
                        buttons: ["OK"],
                        clicked: ""
                    })
                    return;    
                }
            }
            //Check each value meets the regex, although
            //This has been done already on the input elements
        
            switch(key){
                case "CustomerID": //CustomerID shouldn't be the default 0
                    if(val===1){
                        toggleMessageBox({
                            on: true,
                            title: "Error",
                            message: "Please select a customer",
                            buttons: ["OK"],
                            clicked: ""
                        })
                        return;
                    }
                    break;
                case "AgreedPrice":
                    if(val===0){
                        toggleMessageBox({
                            on: true,
                            title: "Error",
                            message: "Please put an Agreed Price greater than 0",
                            buttons: ["OK"],
                            clicked: ""
                        })
                        return;
                    }
                    break;
                default:

                }   
        }
        //If commission is selected, so should be Broker
        if(carData.AgreedCommission > 0 && carData.BrokerID===1){
            toggleMessageBox({
                on: true,
                title: "Error",
                message: "Please select a broker or add one since you selected commission.",
                buttons: ["OK"],
                clicked: ""
            })
            return;
        }
        //If broker is selected, commission can't be 0
        if(carData.BrokerID > 1 && carData.AgreedCommission === 0){
            toggleMessageBox({
                on: true,
                title: "Error",
                message: "Please enter commission amount for the selected broker",
                buttons: ["OK"],
                clicked: ""
            })
            return;
        }
        //Deposit amount can't be 0. That's no deal
        if(carData.TotalPaid === 0){
            toggleMessageBox({
                on: true,
                title: "Error",
                message: "Please add a deposit amount",
                buttons: ["OK"],
                clicked: ""
            })
                return;
        }
        //Release amount can't be 0. Bad business.
        if(carData.ReleaseAmount === 0){
            toggleMessageBox({
                on: true,
                title: "Error",
                message: "Release Amount can't be 0",
                buttons: ["OK"],
                clicked: ""
            })
                return;
        }
        //Initialise ReadyToRelease and StatusFlag
        carData.ReadyToRelease = false;
        carData.StatusFlag = "";
        
        if(carData.ReleaseAmount > carData.AgreedPrice){
            //That's a mistake. Can't pay more than agreed
            toggleMessageBox({
                on: true,
                title: "Error",
                message: "Release amount can't be greater than the agreed total price.",
                buttons: ["OK"],
                clicked: ""
            })
            return;
        }

        if(carData.TotalPaid > carData.ReleaseAmount){
            toggleMessageBox({
                on: true,
                title: "Error",
                message: "Please adjust the release amount to match the deposit or vice versa.",
                buttons: ["OK"],
                clicked: ""
            })
            return;
        }

        if(carData.TotalPaid < carData.ReleaseAmount){
            //It should be committed until release amount is reached
            console.log("Committing");
            carData.StatusFlag = "Committed";
        }

        if(carData.CashCredit==="Cash"){
            //Selected "Cash" deal. Check for consistency
            if(carData.ReleaseAmount < carData.AgreedPrice){
                //Shouldn't be a cash deal0
                toggleMessageBox({
                    on: true,
                    title: "Error",
                    message: "If the release amount is less than the total agreed price, then it's a 'Credit' deal.",
                    buttons: ["OK"],
                    clicked: ""
                })
                return;
            }

            //If the release amount has reached the deposit amount
            //If it's local, deliver it. 
            //Imports need to be committed first to give time to process and register the vehicle
            if(carData.TotalPaid===carData.ReleaseAmount){
                if(carData.Origin==="Local"){
                    //Local: Set the StatusFlag to "Cash"
                    carData.StatusFlag = "Cash";
                    carData.ReadyToRelease = true;
                    //Check that the Sales Agreement Number is provided
                    
                } else {
                    //Imported: Set the status flag to "Committed"
                    //Mark it as ready to release
                    carData.StatusFlag = "Committed";
                    carData.ReadyToRelease = true;
                }
            }
        } else { //Credit
            //Selected "Credit" deal. Check for consistency
            if(carData.ReleaseAmount===carData.AgreedPrice){
                toggleMessageBox({
                    on: true,
                    title: "Error",
                    message: "If the release amount is equal to the total agreed price, then it's a 'Cash' deal.",
                    buttons: ["OK"],
                    clicked: ""
                })
                return;
            }

            //Check that credit period is >=1
            if(carData.CreditPeriod<1){
                toggleMessageBox({
                    on: true,
                    title: "Error",
                    message: "Credit period should be at minimum 1 month",
                    buttons: ["OK"],
                    clicked: ""
                })
                return;
            }

            //If it has reached down payment
            //For Local purchases, set the StatusFlag to "Credit"
            //For Imports, set the StatusFlag to "Committed" and ReadyToRelease to true
            //Imports need to be committed first to give time to process and register the vehicle
            if(carData.TotalPaid===carData.ReleaseAmount){
                if(carData.Origin === "Local"){ //Deliver as credit
                    carData.StatusFlag = "Credit";
                    carData.ReadyToRelease = true;

                } else { //Commit as credit but ready to deliver
                    carData.StatusFlag = "Committed";
                    carData.ReadyToRelease = true;
                }
            }
        }

        deliverVehicle();

    }

    const deliverVehicle = async ()=>{
        //Do checks on the fields then submit
        //Check Delivery date is not null

        if(!carData.DeliveryDate){
            toggleMessageBox({
                on: true,
                title: "Add Delivery Date",
                message: "Please select the delivery date",
                buttons: ['OK']
            })
            return;
        }
        //Check SalesInvoiceNo is not null and conforms to RegExp
        
        if(!carData.SalesInvoiceNo){
            toggleMessageBox({
                on: true,
                title: "Add Sales Agreement No.",
                message: "Please add a sales agreement (SI) number",
                buttons: ['OK']
            });
            return;
        }
        //Check NumberPlate matches is not null AND RegExp
        if(!carData.NumberPlate){ //Not null
            toggleMessageBox({
                on: true,
                title: "Add number plate",
                message: "Please add the number plate in the vehicle details",
                buttons: ['OK']
            });
            return;
            
        } else if(!(new RegExp(fields.NumberPlate.regex)).test(carData.NumberPlate)){ //Matches regexp
            toggleMessageBox({
                on: true,
                title: "Invalid number plate",
                message: "Please a valid number plate",
                buttons: ['OK']
            });
            return;
        }

        //Check customer has reached down payment
        console.log(carData);
        if(carData.TotalPaid!==carData.ReleaseAmount){
            console.log("Insufficient Release Amount");
            toggleMessageBox({
                on: true,
                title: "Insufficient Delivery Amount",
                message: "Vehicle hasn't reached release amount. Please add payments and try again",
                buttons: ["OK"],
            })
            return;
        }

        //Validate carData again
        if (carData.ReleaseAmount >= carData.AgreedPrice) { // Cash
            if (carData.CashCredit === "Credit") { //If they selected "Credit", ask them to select "Cash"
                toggleMessageBox({
                    on: true,
                    title: "Error",
                    message: "If the release amount is equal to the total agreed price, then it's a 'Cash' deal.",
                    buttons: ["OK"],
                    clicked: ""
                })
                return;
            }

            carData.StatusFlag = "Cash";
        } else { // Credit
            if (carData.CashCredit === "Cash") {
                toggleMessageBox({
                    on: true,
                    title: "Error",
                    message: "If the release amount is less than the total agreed price, then it's a 'Credit' deal.",
                    buttons: ["OK"],
                    clicked: ""
                })
                return;
            }

            // If it's credit, deposit amount shouldn't exceed release amount
            if (carData.TotalPaid > carData.ReleaseAmount) {
                toggleMessageBox({
                    on: true,
                    title: "Error",
                    message: "Please adjust the release amount to match the deposit amount.",
                    buttons: ["OK"],
                    clicked: ""
                })
                return;
            } 

            carData.StatusFlag = "Credit";
        }

        console.log("Sending to db as delivered");
        console.log(carData);
        
        fetch("https://api.autodealerug.com/delivercommittedvehicle", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                ...carData,
                DealershipID: globals.DealershipID,
                UserID: globals.userData.UserID,
            })
        }).then( async (res)=>{
            if(res.ok){ //200. Close the modal, locally delete the car?
                //Or wait for the "recordupdate" event and handle it there
                const returnObj = await res.json();
                console.log("Delivery OK. Rows Affected: "+returnObj.data);
            } else { //400, 500. Server issue. Check errors.
                toggleMessageBox({            
                    on: true,
                    title: "Server connection error",
                    message: "Error:" +res.statusText,
                    buttons: ["OK"],
                    clicked: ""});
            }
        }).catch((err)=>{ //Possible connectivity issue
            console.log(`Network? error: ${err.message}`);
            toggleMessageBox({
                on: true,
                title: "Error",
                message: `${err.message}. Please try again.`,
                buttons: ["OK"],
                clicked: ""
            })
        });
    }
    
    return (
        <div className="form-modal">
            <div className="form-modal-dialog">

                <div className="formtabs">
                        <button onClick={()=>setActiveTab(1)} className={activeTab===1?formTabsActiveClasses:formTabsClasses}>Car Details</button>
                        <button onClick={()=>setActiveTab(2)} className={activeTab===2?formTabsActiveClasses:formTabsClasses}>Purchase</button>
                        <button onClick={()=>setActiveTab(3)} className={activeTab===3?formTabsActiveClasses:formTabsClasses}>Logistics</button>
                        <button onClick={()=>setActiveTab(4)} className={activeTab===4?formTabsActiveClasses:formTabsClasses}>Other Expenses</button>
                        <button onClick={()=>setActiveTab(5)} className={activeTab===5?formTabsActiveClasses:formTabsClasses}>Comments</button>
                        <button onClick={()=>setActiveTab(6)} className={activeTab===6?formTabsActiveClasses:formTabsClasses}>Sale Details</button>
                        <button onClick={()=>setActiveTab(7)} className={activeTab===7?formTabsActiveClasses:formTabsClasses}>Payments</button>
                </div>
                
                <div className="edit-form">
                    <FormTab tabindex={1} activeTab={activeTab} _className="tabcontent">
                        <InputPair car={carData} inputName="InvoiceNo" inputType="text" handleChange={handleChange}  handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="ChassisNo" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="ModelYear" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="Make" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="Model" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="EngineType" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="EngineDisplacement" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <SelectPair car={carData} inputName="FuelType" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="Shape" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="NumberPlate" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="Transmission" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>                                                                                             
                        <InputPair car={carData} inputName="Mileage" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="Color" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="Features" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>                                                                                                                                             

                    </FormTab>
                    <FormTab tabindex={2} activeTab={activeTab} _className="tabcontent">
                        {origin==="Local" && <>
                            <label htmlFor="SellerID">{fields.SellerID.displayName}
                                <button onClick={() => setShowAddSellers({visible: true, mode: "add"})} className="open-modal-btn">
                                    Add New
                                </button>
                            </label>
                            <select id="SellerID" onBlur={handleBlur} className="form-control" onChange={handleChange} type="number" name="SellerID" value={carData.SellerID}>
                                <option value={1} key={1}>
                                    -- Select Seller --
                                </option>
                                {
                                    sellers?.map((obj)=>{
                                        return(
                                            <option value={obj.SellerID} key={obj.SellerID}>
                                                {`${obj.FirstName} ${obj.LastName}`}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </>}
                        {origin==="Local" && <>
                            <label htmlFor="PurchaseBrokerID">{fields.PurchaseBrokerID.displayName}
                                <button onClick={() => setShowAddBrokers({visible: true, mode: "add"})} className="open-modal-btn">
                                    Add New
                                </button>
                            </label>
                            <select id="PurchaseBrokerID" onBlur={handleBlur} className="form-control" onChange={handleChange} type="number" name="PurchaseBrokerID" value={carData.PurchaseBrokerID}>
                                <option value={1} key={1}>
                                    -- Select Broker --
                                </option>
                                {
                                    brokers?.map((obj)=>{
                                        return(
                                            <option value={obj.BrokerID} key={obj.BrokerID}>
                                                {`${obj.FirstName} ${obj.LastName}`}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </>}
                        {origin==="Local" && <InputPair car={carData} inputName="PurchaseCommission" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>}
                        <InputPair car={carData} inputName="PurchaseDate" inputType="date" handleChange={handleChange} handleBlur={handleBlur}/> 
                        <InputPair car={carData} inputName="PurchasePrice" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <SelectPair car={carData} inputName="PurchaseCurrency" inputType="text" handleChange={handleChange} handleBlur={handleCurrencyBlur}/>
                        <InputPair car={carData} inputName="ExchangeRate" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        {origin==="Imported" && 
                        <>
                            <InputPair car={carData} inputName="ShippingDate" inputType="date" handleChange={handleChange} handleBlur={handleBlur}/>
                            <InputPair car={carData} inputName="BLNo" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                            <InputPair car={carData} inputName="ShippingCost" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                            <InputPair car={carData} inputName="CifMombasa" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                            <InputPair car={carData} inputName="EtaMombasa" inputType="date" handleChange={handleChange} handleBlur={handleBlur}/>
                            <InputPair car={carData} inputName="LandTranCost" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                            <InputPair car={carData} inputName="ClearingCost" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                            <InputPair car={carData} inputName="TargetExportPrice" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>   
                        </>}
                    </FormTab>
                    <FormTab tabindex={3}  activeTab={activeTab} _className="tabcontent">
                        {origin==="Imported" && 
                        <>
                            <InputPair car={carData} inputName="EtaKla" inputType="date" handleChange={handleChange} handleBlur={handleBlur}/>
                            <InputPair car={carData} inputName="ClearingUG" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        </>}
                        <InputPair car={carData} inputName="BondName" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="WarehouseDate" inputType="date" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="Days" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="DailyDemurrage" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="TotalDemurrage" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>                        
                        <InputPair car={carData} inputName="OtherExpenses" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="TotalCost" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="Duty" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>                        
                        <InputPair car={carData} inputName="TotalCostWithDuty" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="TargetPrice" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>
                        <InputPair car={carData} inputName="TargetCommission" inputType="text" handleChange={handleChange} handleBlur={handleBlur}/>                                                                                          
                    </FormTab>
                    <FormTab tabindex={4}  activeTab={activeTab} _className="tab-others">
                        {formMode==="edit" && <OtherExpenses setCarData={setCarData} VehicleID={carData.VehicleID} parentSheetName={parentSheetName}  parentPKeyName={primary_key} parentPKeyValue={carData[primary_key]}/>}
                    </FormTab>
                    <FormTab tabindex={5}  activeTab={activeTab} _className="tab-others">
                        {formMode==="edit" && <Comments VehicleID={carData.VehicleID} parentSheetName={parentSheetName}/>}
                    </FormTab>
                    <FormTab tabindex={6}  activeTab={activeTab} _className="tab-sell">
                        {formMode==="edit" && 
                            <EditSaleDetails 
                                closeAddEditForm={handleClose} 
                                saleDetails={carData} 
                                backupSaleDetails={carFallBack} 
                                parentSheetName={parentSheetName}
                                setSaleDetails={setCarData}
                                setBackupSaleDetails={setCarFallback}
                                pendingMessages={pendingMessages}
                                customers={customers}
                                brokers={brokers}
                            />}
                    </FormTab>
                    <FormTab tabindex={7}  activeTab={activeTab} _className="tab-carpayments">
                        {formMode==="edit" && <CarPayments DealAgreementNo={carData.DealAgreementNo} parentSheetName={parentSheetName}  parentPKeyName={primary_key} parentPKeyValue={carData[primary_key]}/>}
                    </FormTab>
                </div>
                <div className="modal-controls">
                    {
                        formMode==="add" && <button className="btn btn-warning" 
                        onClick={()=>{
                            showModal({
                                visible: false,
                                mode: ""
                            })
                        }}>Cancel</button>
                    }

                    <button className="btn btn-secondary" 
                    onClick={handleClose}
                    
                    >Close</button>

                    <button className="btn btn-secondary" onClick={cancelDeal}>Cancel Deal</button>
                    <button className="btn btn-secondary" onClick={handleSubmit}>Deliver Vehicle</button>
                </div>

            </div>
            {showAddSellers.visible && <AddEditSeller isModalOpen={showAddSellers} showModal={setShowAddSellers}/>}
            {showAddBrokers.visible && <AddEditBroker isModalOpen={showAddBrokers} showModal={setShowAddBrokers}/>}
        </div>
    )
}

function FormTab({ tabindex, activeTab, children, _className }){
    return (
        <div 
        className={_className}
        style={{display: tabindex === activeTab? "grid": "none"}}
        >
            {children}
        </div>
    )
}

function InputPair({ car, inputName, inputType, handleChange, handleBlur}) {
    //console.log(`${inputName}: ${fields[inputName].displayName}`)
    return (
        <>
            <label htmlFor={inputName}>{`${fields[inputName].displayName}:`}</label>
            <input
                /**Disable if calculated field */
                disabled={fields[inputName].calculated}
                id={inputName} className={`${fields[inputName].required?"form-control required":"form-control"}`} 
                name={inputName} type={inputType}
                value={car[inputName]? utilities.formatIntoInput(car[inputName], fields[inputName].dataType):""}
                onChange={handleChange}
                onBlur={handleBlur}
            ></input>            
        </>
    )
}

function SelectPair({ car, inputName, handleChange, handleBlur }) {
    //console.log(`${inputName}: ${fields[inputName].displayName}`)
    return (
        <>
            <label htmlFor={inputName}>{`${fields[inputName].displayName}:`}</label>
            <select
                id={inputName} 
                className={fields[inputName].required?"form-control required":"form-control"} 
                name={inputName}
                value={car[inputName]? utilities.formatIntoInput(car[inputName], fields[inputName].dataType):""}
                onChange={handleChange}
                onBlur={handleBlur}
            >
                 <option value={"select"}>
                    -- Select --
                </option>
                {
                    fields[inputName].list.map((val)=>{
                        return (
                            <option key={val} value={val}>
                                {val}
                            </option>
                        )
                    })
                } 
            </select>            
        </>
    )
}


function EditSaleDetails({ 
    saleDetails, 
    backupSaleDetails, 
    parentSheetName,
    brokers,
    customers,
    setSaleDetails,
    pendingMessages,
    setBackupSaleDetails
}){
    const [ showAddCustomers, setShowAddCustomers ] = useState({
        visible: false,
        formMode: "add"
    });
    const [ showAddBrokers, setShowAddBrokers ] = useState({
        visible: false,
        formMode: "add"
    });
    //Calculate calculated fields
    Object.keys(saleDetails).forEach((keyName)=>{
        //console.log(`${keyName}: ${saleDetails[keyName]}`);
        switch(keyName){
            case "CreditAmount":
                saleDetails[keyName] = Math.round(saleDetails.CashCredit==="Credit"?saleDetails.AgreedPrice - saleDetails.ReleaseAmount:0);
                break;
            case "MonthlyPayment":
                saleDetails[keyName] = Math.round(saleDetails.CashCredit==="Credit"?(saleDetails.AgreedPrice - saleDetails.ReleaseAmount)/saleDetails.CreditPeriod:0);
                break;
            case "ReleaseBalance":
                saleDetails[keyName] = Math.round(saleDetails.TotalPaid > saleDetails.ReleaseAmount? 0 : saleDetails.TotalPaid - saleDetails.ReleaseAmount);
                break;
            default:
                
        }
    });
    const { sendOrQueue } = utilities;
    const inputClassList = "form-control";
    const inputWrongClassList = "form-control form-control-wrong";
    //Get the global socket
    const globals = useContext(GlobalContext);
    const { toggleMessageBox } = globals;
    const socket = globals.socket;
    const table_name = "Sales";
    const primary_key = "DealAgreementNo";

    const handleChange = ({ target })=>{

        //if target.name === "CashCredit", In cash, release amount should follow
        //AgreedPrice, credit period, monthlyPayment should be 0
        //CreditPeriod should be deactivated
        if(target.name==="CashCredit"){
            if(target.value==="Cash"){ //Credit -> Cash
                saleDetails.CreditPeriod = 0;
            } else { //Cash -> Credit
                saleDetails.CreditPeriod = 3;
            }
        }
        //Check against the regex if it exists
        const regexx = fields[target.name].regex || null
        if(regexx && !(new RegExp(regexx)).test(utilities.formatFromInput(target.value, fields[target.name].dataType))){ //If it fails the regex test
            //During a delete operation, newVal.length < oldVal.length
            //In that case, allow the deletion by setting the new Value
            //Else, we are performing an addition over the maximum length,
            //In which case, reject it!
            setSaleDetails(prev=>{
                if(prev[target.name].toString().length > target.value.toString().length){
                    //Accept the change
                    return {...prev, [target.name]: utilities.formatFromInput(target.value, fields[target.name].dataType)}
                } else {
                    //Reject the change, return previous.
                    return prev;
                }
            })

            //Return before
            target.className = inputWrongClassList;
        } else {
            target.className = inputClassList;
            setSaleDetails((prev)=>{
                return {...prev, [target.name]: utilities.formatFromInput(target.value, fields[target.name].dataType)}
            })
        }
    }
    const handleBlur = ({ target })=>{
        //In edit mode: update with websockets AND set the state
        //If it's a number, remove commas
        let newVal = utilities.formatFromInput(target.value, fields[target.name].dataType);
        let prevVal = utilities.formatFromInput(backupSaleDetails[target.name], fields[target.name].dataType);
        //console.log(`New val: ${newVal}, Prev val: ${prevVal}`);
        if(newVal!=prevVal) { //It has been changed
            const msg = {
                DealershipID: globals.DealershipID,
                UserID: globals.userData.UserID,
                ownSheetName: parentSheetName,
                table: table_name,
                primaryKeyName: primary_key,
                primaryKeyValue: saleDetails.DealAgreementNo,
                fieldName: target.name,
                fieldValue: newVal,
                triggerRefresh: fields[target.name].triggerRefresh
            };

            sendOrQueue(msg, socket, pendingMessages.current, saleDetails, backupSaleDetails, setSaleDetails);
        }
    }

    const handleBlurForFieldsInDeliveries = ({ target })=>{
        if(parentSheetName==="Committed") return;
        //In edit mode: update with websockets AND set the state
        //If it's a number, remove commas
        let newVal = utilities.formatFromInput(target.value, fields[target.name].dataType);
        let prevVal = utilities.formatFromInput(backupSaleDetails[target.name], fields[target.name].dataType);
        //console.log(`New val: ${newVal}, Prev val: ${prevVal}`);
        if(newVal!=prevVal) { //It has been changed
            const msg = {
                DealershipID: globals.DealershipID,
                UserID: globals.userData.UserID,
                ownSheetName: parentSheetName,
                table: "Deliveries",
                primaryKeyName: "DealAgreementNo",
                primaryKeyValue: saleDetails.DealAgreementNo,
                fieldName: target.name,
                fieldValue: newVal,
                triggerRefresh: fields[target.name].triggerRefresh
            };

            sendOrQueue(msg, socket, pendingMessages.current, saleDetails, backupSaleDetails, setSaleDetails);
        }
    }

    const fieldUpdateListener = (msg)=>{
        if(msg.sheetName!==parentSheetName){
            //Only handle events for this sheet
            return;
          }
        console.log(msg);
        //Get the Field and Status {ok: true/false, fieldName: fieldName}
        if(msg.ok){ //Update was successful
            //Update the fallback, then refresh
            setBackupSaleDetails({...backupSaleDetails, [msg.fieldName]: saleDetails[msg.fieldName]});
        } else { //Check and log msg.error
            //Show error and revert to fallback
            setSaleDetails({...saleDetails, [msg.fieldName]:backupSaleDetails[msg.fieldName]});
        }
    }
    const ioReconnectListener = ()=>{
        let attempts = 10;
        let currMsg = {};
        //Check the queue for any messages for transmission
        while(pendingMessages.current.length>0) {
            console.log(`${pendingMessages.current.length} messages in queue`);
            //Limit no. of attempts to 100 to prevent endless loop
            currMsg = pendingMessages.current.shift();
            sendOrQueue(currMsg, socket, pendingMessages.current, saleDetails, backupSaleDetails, setSaleDetails);
            if(attempts===0){
                break;
            }
            attempts--;
        }
    }
    const replaceRecordListener = (msg)=>{
        if(msg.sheetName!==parentSheetName) return; //Different sheet
        if(saleDetails[msg.primaryKeyName]!==msg.primaryKeyValue) return; //Primary keys don't match
        const car = msg.parentRecord;
        
        setBackupSaleDetails(prev=>{
            Object.keys(car).forEach((property)=>{
                if(fields[property].dataType === "integer" || fields[property].dataType === "decimal"){
                    car[property] = Number(car[property])
                }
                if(fields[property].dataType === "date"){
                    car[property] = UtilityFunctions.formatIntoInput(car[property], "date");
                }
            })   
            return {...car, CreditAmount: 0, MonthlyPayment: 0, SalesInvoiceNo: "", DeliveryDate: car.DeliveryDate || "" /*utilities.formatFromInput((new Date()).toISOString(), "date")*/};
        });

        setSaleDetails(prev=>{
            Object.keys(car).forEach((property)=>{
                if(fields[property].dataType === "integer" || fields[property].dataType === "decimal"){
                    car[property] = Number(car[property])
                }
                if(fields[property].dataType === "date"){
                    car[property] = UtilityFunctions.formatIntoInput(car[property], "date");
                }
            })   
            console.log("Delivery Date: ", car.DeliveryDate);
            return {...car, CreditAmount: 0, MonthlyPayment: 0, SalesInvoiceNo: "", DeliveryDate: car.DeliveryDate || "" /*utilities.formatFromInput((new Date()).toISOString(), "date")*/};
        }); 

    }
    useEffect(()=>{
        //Add event listeners to the websocket
        //Return a function to remove them when component dismounts
        if(socket.disconnected){
            socket.connect()
        }
        socket.on('update_record', fieldUpdateListener);
        socket.on('replace_record', replaceRecordListener);

        socket.on('connect',ioReconnectListener);

        return ()=>{
            socket.off('update_record',fieldUpdateListener);
            socket.off('replace_record', replaceRecordListener);
            socket.off('connect',ioReconnectListener);
        }
    },[]);


    return (
        <div className="edit-selldata">
            <div className="form-selldata">
            
                <label htmlFor="DealReferenceNo" disabled={fields.DealReferenceNo.calculated}>{fields.DealReferenceNo.displayName}</label>
                <input id="DealReferenceNo" onBlur={handleBlur} className="form-control required" onChange={handleChange} type="text" name="DealReferenceNo" value={saleDetails.DealReferenceNo}></input>

                <label htmlFor="CommitmentDate" disabled={fields.CommitmentDate.calculated}>{fields.CommitmentDate.displayName}</label>
                <input id="CommitmentDate" onBlur={handleBlur} className="form-control required" onChange={handleChange} type="date" name="CommitmentDate" value={saleDetails.CommitmentDate}></input>
                
                <label htmlFor="CashCredit" disabled={fields.CashCredit.calculated}>{fields.CashCredit.displayName}</label>
                <select id="CashCredit" onBlur={handleBlur} className="form-control required" onChange={handleChange} type="text" name="CashCredit" value={saleDetails.CashCredit}>
                    <option value="Cash">
                        Cash
                    </option>
                    <option value="Credit">
                        Credit
                    </option>
                </select>
                
                <label htmlFor="AgreedPrice">{fields.AgreedPrice.displayName}</label>
                <input id="AgreedPrice" onBlur={handleBlur} className="form-control required" onChange={handleChange} type="text" name="AgreedPrice" value={utilities.formatIntoInput(saleDetails.AgreedPrice, fields["AgreedPrice"].dataType)}></input>

                <label htmlFor="Currency" >{fields.Currency.displayName}</label>
                <select id="Currency" onBlur={handleBlur} className="form-control required" onChange={handleChange} type="text" name="Currency" value={saleDetails.Currency}>
                    <option value="UGX">
                        UGX
                    </option>
                    <option value="USD">
                        USD
                    </option>
                </select>

                <label htmlFor="ReleaseAmount" >{fields.ReleaseAmount.displayName}</label>
                <input id="ReleaseAmount" onBlur={handleBlur} className="form-control" onChange={handleChange} type="text" name="ReleaseAmount" value={utilities.formatIntoInput(saleDetails.ReleaseAmount, fields["ReleaseAmount"].dataType)}></input>

                <label htmlFor="CreditAmount">{fields.CreditAmount.displayName}</label>
                <input disabled id="CreditAmount" onBlur={handleBlur} className="form-control" onChange={handleChange} type="text" name="CreditAmount" value={utilities.formatIntoInput(saleDetails.CreditAmount, fields["CreditAmount"].dataType)}></input>

                <label htmlFor="CreditPeriod">{fields.CreditPeriod.displayName}</label>
                <input id="CreditPeriod" onBlur={handleBlur} className="form-control" onChange={handleChange} type="text" name="CreditPeriod" value={saleDetails.CreditPeriod} disabled={saleDetails.CashCredit==="Cash"?true:false}></input>

                <label htmlFor="MonthlyPayment">{fields.MonthlyPayment.displayName}</label>
                <input disabled id="MonthlyPayment" onBlur={handleBlur} className="form-control" onChange={handleChange} type="text" name="MonthlyPayment" value={utilities.formatIntoInput(saleDetails.MonthlyPayment, fields["MonthlyPayment"].dataType)}></input>

                <label htmlFor="BrokerID">{fields.BrokerID.displayName}
                    <button onClick={() => setShowAddBrokers({visible: true, mode: "add"})} className="open-modal-btn">
                        Add New
                    </button>
                </label>
                <select id="BrokerID" onBlur={handleBlur} className="form-control" onChange={handleChange} type="number" name="BrokerID" value={saleDetails.BrokerID}>
                    <option value={1} key={1}>
                        -- Select Broker --
                    </option>
                    {
                        brokers?.map((obj)=>{
                            return(
                                <option value={obj.BrokerID} key={obj.BrokerID}>
                                    {`${obj.FirstName} ${obj.LastName}`}
                                </option>
                            )
                        })
                    }
                </select>

                <label htmlFor="AgreedCommission">{fields.AgreedCommission.displayName}</label>
                <input id="AgreedCommission" onBlur={handleBlur} className="form-control" onChange={handleChange} type="text" name="AgreedCommission" value={utilities.formatIntoInput(saleDetails.AgreedCommission, fields["AgreedCommission"].dataType)}></input>

                <label htmlFor="CommissionCurrency" >{fields.CommissionCurrency.displayName}</label>
                <select id="CommissionCurrency" onBlur={handleBlur} className="form-control" onChange={handleChange} type="text" name="CommissionCurrency" value={saleDetails.CommissionCurrency}>
                    <option value="UGX">
                        UGX
                    </option>
                    <option value="USD">
                        Credit
                    </option>
                </select>

                <label htmlFor="CustomerID">{fields.CustomerID.displayName}
                    <button onClick={() => setShowAddCustomers({visible: true, mode: "add"})} className="open-modal-btn">
                        Add New
                    </button>
                </label>
                <select id="CustomerID" onBlur={handleBlur} className="form-control required" onChange={handleChange} type="number" name="CustomerID" value={saleDetails.CustomerID}>
                    <option value={1} key={1}>
                        -- Select Customer --
                    </option>
                    {
                        customers?.map((obj)=>{
                            return(
                                <option value={obj.CustomerID} key={obj.CustomerID}>
                                    {`${obj.IsCompany?obj.CompanyName:obj.FirstName+" "+obj.LastName}`}
                                </option>
                            )
                        })
                    }
                </select>

                <label htmlFor="TotalPaid">{fields.TotalPaid.displayName}</label>
                <input id="TotalPaid" onBlur={handleBlur} className="form-control required" onChange={handleChange} type="text" name="TotalPaid" value={utilities.formatIntoInput(saleDetails.TotalPaid, fields["TotalPaid"].dataType)}></input>

                <label htmlFor="TotalBalance">{fields.TotalBalance.displayName}</label>
                <input id="TotalBalance" onBlur={handleBlur} className="form-control required" onChange={handleChange} type="text" name="TotalBalance" value={utilities.formatIntoInput(saleDetails.TotalBalance, fields["TotalBalance"].dataType)}></input>

                <label htmlFor="ReleaseBalance">{fields.ReleaseBalance.displayName}</label>
                <input id="ReleaseBalance" onBlur={handleBlur} className="form-control required" onChange={handleChange} type="text" name="ReleaseBalance" value={utilities.formatIntoInput(saleDetails.ReleaseBalance, fields["ReleaseBalance"].dataType)} disabled></input>
            
                <label required htmlFor="DeliveryDate">{fields.DeliveryDate.displayName}:</label>
                <input id="DeliveryDate" onBlur={handleBlurForFieldsInDeliveries} className="form-control" onChange={handleChange} type="date" name="DeliveryDate" value={saleDetails.DeliveryDate}></input>

                <label required htmlFor="SalesInvoiceNo">{fields.SalesInvoiceNo.displayName}:</label>
                <input id="SalesInvoiceNo" onBlur={handleBlurForFieldsInDeliveries} className="form-control" onChange={handleChange} type="text" name="SalesInvoiceNo" value={saleDetails.SalesInvoiceNo}></input>
            </div>
            {showAddCustomers.visible && <AddEditCustomer isModalOpen={showAddCustomers} showModal={setShowAddCustomers}/>}
            {showAddBrokers.visible && <AddEditBroker isModalOpen={showAddBrokers} showModal={setShowAddBrokers}/>}
        </div>
    )
}



export default AddEditCommitted;