const fields = {
    DealershipID: {
        displayName: "DealershipID",
        dataType: "string",
        public: false,
        required: false,
        triggerRefresh: false
    },
    Origin: {
        displayName: "Origin",
        dataType: "string",
        public: true,
        required: false,
        triggerRefresh: false
    },
    VehicleID: {
        displayName: "VehicleID",
        dataType: "integer",
        public: false,
        required: false,
        triggerRefresh: false
    },
    InvoiceNo: {
        displayName: "Invoice No",
        dataType: "string",
        public: false,
        calculated: false,
        regex: "^[a-zA-Z0-9_\\-]{0,30}$|null",
        required: false,
        triggerRefresh: false
    },
    ChassisNo: {
        displayName: "Chassis No.",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[a-zA-Z0-9\\-]{8,20}$",
        required: true,
        triggerRefresh: false
    },
    PurchaseDate: {
        displayName: "Purchase Date",
        dataType: "date",
        calculated: false,
        public: false,
        required: true,
        triggerRefresh: false
    },
    PurchasePrice: {
        displayName: "Purchase Price",
        dataType: "decimal",
        public: false,
        calculated: false,
        regex: "^[1-9][0-9]{1,10}$",
        required: true,
        triggerRefresh: true
    },
    ExchangeRate: {
        displayName: "Exchange Rate",
        dataType: "decimal",
        public: false,
        calculated: false,
        regex: "^[1-9]$|^[1-9][0-9]{1,10}$",
        required: true,
        triggerRefresh: true
    },
    ModelYear: {
        displayName: "Model Year",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^$|^[0-9]{4,4}$|null",
        required: false,
        triggerRefresh: false
    },
    Make: {
        displayName: "Make",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[a-zA-Z]{4,10}$",
        required: true,
        triggerRefresh: false
    },
    Model: {
        displayName: "Model",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[a-zA-Z0-9 ]{1,30}$",
        required: true,
        triggerRefresh: false
    },
    EngineType: {
        displayName: "Engine Type",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[a-zA-Z0-9\\-]{0,10}$|null",
        required: false,
        triggerRefresh: false
    },
    EngineDisplacement: {
        displayName: "Displacement (cc)",
        dataType: "integer",
        public: true,
        calculated: false,
        required: false,
        triggerRefresh: false
    },
    FuelType: {
        displayName: "Fuel Type",
        dataType: "string",
        calculated: false,
        public: true,
        list: ['Petrol', 'Diesel', 'Hybrid', 'Electric'],
        required: true,
        triggerRefresh: false
    },
    Shape: {
        displayName: "Shape",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[a-zA-Z ]{0,15}$|null",
        required: false,
        triggerRefresh: false
    },
    NumberPlate: {
        displayName: "Number Plate",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[a-zA-Z0-9]{0,10}$|null",
        required: false,
        triggerRefresh: false
    },
    Transmission: {
        displayName: "Transmission",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^$|^[a-zA-Z0-9]{2,3}$|null",
        required: false,
        triggerRefresh: false
    },
    Mileage: {
        displayName: "Mileage (Km)",
        dataType: "decimal",
        public: true,
        calculated: false,
        regex: "^[0-9]{1,7}$",
        required: false,
        triggerRefresh: false
    },
    Color: {
        displayName: "Color",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[a-zA-Z ]{0,20}$|null",
        required: false,
        triggerRefresh: false
    },
    Features: {
        displayName: "Extra Features",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^$|^[a-zA-Z0-9 \\-_,&.]{0,100}$|null",
        required: false,
        triggerRefresh: false
    },
    ShippingDate: {
        displayName: "Shipping Date",
        dataType: "date",
        calculated: false,
        public: false,
        required: false,
        triggerRefresh: false
    },
    BLNo: {
        displayName: "BL Number",
        dataType: "string",
        public: false,
        calculated: false,
        regex: "^[A-Za-z0-9]{0,30}$",
        required: false,
        triggerRefresh: false
    },
    ShippingCost: {
        displayName: "Shipping Cost (USD)",
        dataType: "decimal",
        calculated: false,
        public: false,
        required: false,
        triggerRefresh: true
    },
    CifMombasa: {
        displayName: "CIF Mombasa (USD)",
        dataType: "decimal",
        public: false,
        calculated: true,
        regex: "^[0-9]{1,10}$",
        required: false,
        triggerRefresh: true
    },
    EtaMombasa: {
        displayName: "ETA Mombasa",
        dataType: "date",
        calculated: false,
        public: false,
        required: false,
        triggerRefresh: false
    },
    LandTranCost: {
        displayName: "Inland Transport",
        dataType: "decimal",
        public: false,
        calculated: false,
        regex: "^[0-9]{1,10}$",
        required: false,
        triggerRefresh: true
    },
    EtaKla: {
        displayName: "ETA Kla",
        dataType: "date",
        calculated: false,
        public: false,
        required: false,
        triggerRefresh: false
    },
    ClearingCost: {
        displayName: "Clearing Cost MBA",
        dataType: "decimal",
        public: false,
        calculated: false,
        regex: "^[0-9]{1,10}$",
        required: false,
        triggerRefresh: true
    },
    TargetExportPrice: {
        displayName: "Target Export Price",
        dataType: "decimal",
        public: true,
        calculated: false,
        regex: "^[0-9]{1,10}$",
        required: false,
        triggerRefresh: false
    },
    ClearingUG: {
        displayName: "Clearing Cost KLA",
        dataType: "decimal",
        public: false,
        calculated: false,
        regex: "^[0-9]{1,10}$",
        required: false,
        triggerRefresh: true
    },
    WarehouseDate: {
        displayName: "WarehouseDate",
        dataType: "date",
        calculated: false,
        public: true,
        required: true,
        triggerRefresh: true
    },
    BondName: {
        displayName: "Address / Bond Name",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[a-zA-Z0-9 ]{3,30}$",
        required: true,
        triggerRefresh: false
    },
    CompanyAddress: {
        displayName: "Address / Bond Name",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[a-zA-Z0-9 ]{3,30}$",
        required: true,
        triggerRefresh: false
    },
    Bond: {
        displayName: "Bond Name",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[a-zA-Z0-9 ]{3,30}$",
        required: false,
        triggerRefresh: false
    },
    DailyDemurrage: {
        displayName: "Daily Demurrage",
        dataType: "decimal",
        public: true,
        calculated: false,
        regex: "^[1-9][0-9]{1,10}$",
        required: true,
        triggerRefresh: true
    },
    Duty: {
        displayName: "Duty",
        dataType: "decimal",
        public: true,
        calculated: false,
        regex: "^[0-9]{1,10}$",
        required: false,
        triggerRefresh: true
    },
    TargetCommission: {
        displayName: "Target Commission",
        dataType: "decimal",
        public: false,
        calculated: false,
        regex: "^[0-9]{1,10}$",
        required: false,
        triggerRefresh: false
    },
    TargetPrice: {
        displayName: "Target Price",
        dataType: "decimal",
        public: true,
        calculated: false,
        regex: "^[0-9]{1,10}$",
        required: false,
        triggerRefresh: false
    }, /*Beyond here a re all calculated fields */
    Days: {
        displayName: "Days In Stock",
        dataType: "integer",
        calculated: true,
        public: true,
        triggerRefresh: true
    },
    DaysCommitted: {
        displayName: "Days Committed",
        dataType: "integer",
        calculated: true,
        public: true,
        triggerRefresh: true
    },
    DaysOverdue: {
        displayName: "Days Overdue",
        dataType: "integer",
        calculated: true,
        public: true,
        triggerRefresh: true
    },
    StatusFlag: {
        displayName: "Status",
        dataType: "string",
        calculated: false,
        public: false,
        triggerRefresh: false
    },
    TotalDemurrage: {
        displayName: "Total Demurrage (VAT Inc.)",
        dataType: "decimal",
        calculated: true,
        public: true
    },
    OtherExpenses: { //TODO: Change to CamelCase
        displayName: "Other Expenses",
        dataType: "decimal",
        calculated: true,
        public: true
    },
    Demurrage: { //TODO: Change to CamelCase
        displayName: "Commit Demurrage",
        dataType: "decimal",
        calculated: true,
        public: true
    },
    TotalCost: {
        displayName: "Sub Total",
        dataType: "decimal",
        calculated: true,
        public: true
    },
    TotalCredit: {
        displayName: "Total Credit",
        dataType: "decimal",
        calculated: true,
        public: true
    },
    TotalPenalty: {
        displayName: "TotalPenalty",
        dataType: "decimal",
        calculated: true,
        public: true
    },
    TotalPaid: {
        displayName: "Total Paid",
        dataType: "decimal",
        calculated: true,
        public: true
    },
    ReleaseBalance: {
        displayName: "Release Balance",
        dataType: "decimal",
        calculated: true,
        public: true
    },
    TotalBalance: {
        displayName: "Total Balance",
        dataType: "decimal",
        calculated: true,
        public: true
    },
    TotalCostWithDuty: {
        displayName: "Total Cost",
        dataType: "decimal",
        calculated: true,
        public: true
    },
    DealAgreementNo: {
        displayName: "Deal Agreement No.",
        dataType: "string",
        public: false,
        calculated: false,
        regex: "^[a-zA-Z0-9\\-_]{1,20}$",
        required: true,
        triggerRefresh: false
    },
    SalesInvoiceNo: {
        displayName: "Sales Agreement No.",
        dataType: "string",
        public: false,
        calculated: false,
        regex: "^$|^[a-zA-Z0-9\\-_]{1,20}$",
        required: false, //Depends
        triggerRefresh: false
    },

    DealReferenceNo: {
        displayName: "Deal Reference No",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[a-zA-Z0-9\\-_]{1,20}$",
        required: true,
        triggerRefresh: false
    },
    IDType: {
        displayName: "ID Type",
        dataType: "string",
        public: true,
        calculated: false,
        list: ['National ID', 'Driving Permit', 'Other'],
        required: false,
        triggerRefresh: false
    },
    IDNumber: {
        displayName: "ID Number",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[a-zA-Z0-9]{0,15}$",
        required: false,
        triggerRefresh: false
    },
    Address: {
        displayName: "Address",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[a-zA-Z0-9- ,&.]{0,60}$",
        required: true,
        triggerRefresh: false
    },
    IsCompany: {
        displayName: "Is Company",
        dataType: "boolean",
        public: false,
        calculated: false,
        required: false,
        triggerRefresh: false
    },
    CompanyName: {
        displayName: "Company Name",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^$|^[a-zA-Z0-9 &.]{3,40}$",
        required: false,
        triggerRefresh: false
    },
    DealershipName: {
        displayName: "Company Name",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[a-zA-Z0-9 &.]{3,40}$",
        required: true,
        triggerRefresh: false
    },
    CompanyPhone1: {
        displayName: "Phone 1",
        dataType: "string",
        public: true, //Apears in table??
        calculated: true,
        regex: "^[0-9]{12,12}$",
        required: true,
        triggerRefresh: false
    },

    CompanyPhone2: {
        displayName: "Phone 2",
        dataType: "string",
        public: true, //Apears in table??
        calculated: true,
        regex: "^[0-9]{12,12}$",
        required: true,
        triggerRefresh: false
    },
    Name: {
        displayName: "Name",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[A-Za-z ]{0,30}$",
        required: true,
        triggerRefresh: false
    },
    FirstName: {
        displayName: "First Name",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[A-Za-z ]{0,30}$",
        required: true,
        triggerRefresh: false
    },
    LastName: {
        displayName: "Last Name",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[A-Za-z ]{0,30}$",
        required: true,
        triggerRefresh: false
    },
    AccountName: {
        displayName: "Account Name",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[A-Za-z0-9]{0,30}$",
        required: true,
        triggerRefresh: false
    },
    ExpenseID: {
        displayName: "Expense ID",
        dataType: "string",
        public: false,
        required: true,
        triggerRefresh: false
    },
    ExpenseName: {
        displayName: "Expense",
        dataType: "string",
        public: true,
        regex: "^[A-Za-z0-9]{0,30}$",
        required: true,
        triggerRefresh: false
    },
    PaymentID: {
        displayName: "PaymentID",
        dataType: "integer",
        public: false,
        required: true,
        triggerRefresh: false
    },
    AdvAr: {
        displayName: "ADV/AR",
        dataType: "string",
        calculated: false,
        public: true,
        list: ['ADV', 'AR'],
        required: false,
        triggerRefresh: false
    },
    PaymentDate: {
        displayName: "Payment Date",
        dataType: "date",
        public: true,
        required: true,
        triggerRefresh: false
    },
    LastPmtDate: {
        displayName: "Last Paid",
        dataType: "date",
        public: true,
        required: true,
        calculated: true,
        triggerRefresh: false
    },
    DueDate: {
        displayName: "Due Date",
        dataType: "date",
        public: true,
        required: true,
        triggerRefresh: false
    },
    Amount: {
        displayName: "Amount",
        dataType: "decimal",
        public: true,
        required: false,
        triggerRefresh: true
    },
    GrossProfit: {
        displayName: "GrossProfit",
        dataType: "decimal",
        public: true,
        required: false,
        triggerRefresh: true
    },
    Details: {
        displayName: "Details",
        dataType: "string",
        public: true,
        regex: "^[^*={}><]{0,255}$",
        required: false,
        triggerRefresh: false
    },
    CommentID: {
        displayName: "CommentID",
        dataType: "integer",
        public: false,
        required: true,
        triggerRefresh: false
    },
    UserName: {
        displayName: "Username",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[A-Za-z0-9@ ]{0,30}$",
        required: true,
        triggerRefresh: false
    },
    Password: {
        displayName: "Password",
        dataType: "string",
        public: false,
        calculated: false,
        regex: "^[^\n\r]{0,20}$",
        required: true,
        triggerRefresh: false
    },
    MessagesPassword: {
        displayName: "Password",
        dataType: "string",
        public: false,
        calculated: false,
        regex: "^[^\n\r]{0,20}$",
        required: true,
        triggerRefresh: false
    },
    ConfirmPassword: {
        displayName: "Password",
        dataType: "string",
        public: false,
        calculated: false,
        regex: "^[^\n\r]{0,20}$",
        required: true,
        triggerRefresh: false
    },
    UserID: {
        displayName: "UserID",
        dataType: "integer",
        public: false,
        required: true,
        triggerRefresh: false
    },
    UserComment: {
        displayName: "Comment",
        dataType: "string",
        public: true,
        regex: "^[^*={}><]{0,255}$",
        required: true,
        triggerRefresh: false
    },
    Remarks: {
        displayName: "Comment",
        dataType: "string",
        public: true,
        regex: "^[^*={}><]{0,255}$",
        required: true,
        triggerRefresh: false
    },
    CommentDate: {
        displayName: "Comment Date",
        dataType: "date",
        public: true,
        required: true,
        triggerRefresh: false
    },
    Time_Stamp: {
        displayName: "Time Stamp",
        dataType: "string",
        public: false,
        required: false,
        triggerRefresh: false
    },
    DealNo: {
        displayName: "Deal Reference Number",
        dataType: "string",
        public: true,
        regex: "^[a-zA-Z0-9]{1,12}$",
        required: true,
        triggerRefresh: false
    },
    CommitmentDate: {
        displayName: "Commitment Date",
        dataType: "date",
        public: true,
        required: true,
        triggerRefresh: true
    },
    CashCredit: {
        displayName: "Cash Or Credit",
        dataType: "string",
        calculated: false,
        public: true,
        list: ['Cash', 'Credit'],
        required: true,
        triggerRefresh: false
    },
    AgreedPrice: {
        displayName: "Agreed Price",
        dataType: "decimal",
        public: true, //Apears in table??
        calculated: false,
        regex: "^[1-9][0-9]{0,9}$",
        required: true,
        triggerRefresh: true
    },
    Currency: {
        displayName: "Currency",
        dataType: "string",
        calculated: false,
        public: false,
        list: ['UGX', 'USD'],
        required: true,
        triggerRefresh: true
    },
    PurchaseCurrency: {
        displayName: "Currency",
        dataType: "string",
        calculated: false,
        public: false,
        list: ['UGX', 'USD'],
        required: true,
        triggerRefresh: true
    },
    ReleaseAmount: {
        displayName: "Release Amount",
        dataType: "decimal",
        public: true, //Apears in table??
        calculated: false,
        regex: "^[0-9]{1,10}$",
        required: true,
        triggerRefresh: true
    },
    CreditAmount: {
        displayName: "CreditAmount",
        dataType: "decimal",
        public: true, //Apears in table??
        calculated: true,
        regex: "^[0-9]{1,10}$",
        required: true
    },
    BrokerID: {
        displayName: "Broker",
        dataType: "integer",
        public: false,
        required: false,
        triggerRefresh: false
    },
    SellerID: {
        displayName: "Seller",
        dataType: "integer",
        public: false,
        required: false,
        triggerRefresh: false
    },
    PurchaseBrokerID: {
        displayName: "Broker",
        dataType: "integer",
        public: false,
        required: false,
        triggerRefresh: false
    },
    MonthlyPayment: {
        displayName: "Monthly Payment",
        dataType: "decimal",
        public: true, //Apears in table??
        calculated: true,
        regex: "^[0-9]{1,10}$",
        required: true
    },
    CustomerID: {
        displayName: "Customer",
        dataType: "integer",
        public: false,
        required: true,
        triggerRefresh: false
    },
    BrokerName: {
        displayName: "Name",
        dataType: "string",
        public: false,
        calculated: false,
        regex: "^[A-Za-z ]{2,30}$",
        required: false,
        triggerRefresh: false
    },
    CustomerName: {
        displayName: "Customer",
        dataType: "string",
        public: true,
        calculated: false,
        regex: "^[A-Za-z ]{8,30}$",
        required: false,
        triggerRefresh: false
    },
    ContactPerson: {
        displayName: "Customer",
        dataType: "string",
        public: false,
        calculated: false,
        regex: "^[A-Za-z ]{8,30}$",
        required: false,
        triggerRefresh: false
    },
    AgreedCommission: {
        displayName: "Commission",
        dataType: "decimal",
        public: false, //Apears in table??
        calculated: false,
        regex: "^[0-9]{1,10}$",
        required: true,
        triggerRefresh: true
    },
    PurchaseCommission: {
        displayName: "Commission",
        dataType: "decimal",
        public: false, //Apears in table??
        calculated: false,
        regex: "^[0-9]{1,10}$",
        required: true,
        triggerRefresh: true
    },
    CommissionCurrency: {
        displayName: "Commission Currency",
        dataType: "string",
        calculated: false,
        public: false,
        list: ['UGX', 'USD'],
        required: true,
        triggerRefresh: false
    },
    CreditPeriod: {
        displayName: "CreditPeriod",
        dataType: "integer",
        calculated: false,
        public: false,
        required: true,
        triggerRefresh: true
    },
    DepositAmount: {
        displayName: "Deposit",
        dataType: "decimal",
        public: true, //Apears in table??
        calculated: false,
        regex: "^[0-9]{1,10}$",
        required: true,
        triggerRefresh: true
    },
    PaymentMethodID: {
        displayName: "Payment Method",
        dataType: "integer",
        public: false,
        required: true,
        triggerRefresh: false
    },
    Phone1: {
        displayName: "Phone 1",
        dataType: "string",
        public: true, //Apears in table??
        calculated: true,
        regex: "^[0-9]{12,12}$",
        required: true,
        triggerRefresh: false
    },
    Phone2: {
        displayName: "Phone 2",
        dataType: "string",
        public: true, //Apears in table??
        calculated: true,
        regex: "^$|^[0-9]{12}$",
        required: false,
        triggerRefresh: false
    },
    ContactPhone1: {
        displayName: "Contact Phone 1",
        dataType: "string",
        public: true, //Apears in table??
        calculated: true,
        regex: "^[0-9]{12,12}$",
        required: true,
        triggerRefresh: false
    },
    ContactPhone2: {
        displayName: "Contact Phone 2",
        dataType: "string",
        public: true, //Apears in table??
        calculated: true,
        regex: "^$|^[0-9]{12}$",
        required: false,
        triggerRefresh: false
    },
    DeliveryDate: {
        displayName: "Delivery Date",
        dataType: "date",
        public: true,
        required: false, //Depends
        triggerRefresh: false
    },
}

export default fields;