// 'use client';
import { useEffect, useState, useContext } from "react";
import {  
  Table, TableBody, TableCell, TableHead, Flex, 
  AreaChart, Card, Title, Select, SelectItem, 
  Tab, TabGroup, TabList, TabPanel, TabPanels, Text, 
  TableRow, TableHeaderCell,
  Metric
} from "@tremor/react";

// Import chart components specifically
import { LineChart } from "@tremor/react";
import { BarChart } from "@tremor/react";

//import * as Select from '@radix-ui/react-select';
//import { ChevronDownIcon, CheckIcon } from 'lucide-react';

import { GlobalContext } from "./Cars";

export default function DashGrid() {
  return (
    <div className="flex flex-wrap">
      <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
        <StockTrendsAreaChart />
      </div>
      <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
        <SalesTrendBarChart />
      </div>
      <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
        <OutstandingCreditChart />
      </div>
      <div >
        <CreditCollectionOverview />
      </div>
    </div>
  );
}

const StockTrendsAreaChart = () => {
  const [chartData, setChartData] = useState([]);
  const { DealershipID } = useContext(GlobalContext);
  const [months, setMonths] = useState(3); // Default: Last 3 months

  const fetchData = async () => {
    try {
      const response = await fetch("https://api.autodealerug.com/getstocktrend", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({
          DealershipID,
          months,
        })
      });
      const result = await response.json();
      const formattedData = result.data.map((item) => ({
        date: new Date(item.StockDate).toLocaleDateString("en-GB"),
        LocalStock: item.LocalStock,
        ImportedStock: item.ImportedStock,
      }));

      setChartData(formattedData);
    } catch (err) {
      console.error("Error fetching stock data:", err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [months]);

  return (
    <Card>
      <div className="flex justify-between items-center">
        <Title className='flex-1'>{`Stock: ${chartData.length ? chartData[chartData.length - 1].LocalStock + chartData[chartData.length - 1].ImportedStock : 0} Cars`}</Title>
        <Select className='flex-1' value={months.toString()} onValueChange={(value) => setMonths(Number(value))}>
          {[1, 2, 3, 4, 5, 6].map((num) => (
            <SelectItem key={num} value={num.toString()}>
              Last {num} {num === 1 ? "month" : "months"}
            </SelectItem>
          ))}
        </Select>
      </div>

      <AreaChart
        className="h-[210px]"
        data={chartData}
        index="date"
        categories={["LocalStock", "ImportedStock"]}
        colors={["blue", "red"]}
        yAxisLabel="Stock level"
        yAxisWidth={20}
        showLegend={true}
        showAnimation={true}
        showGridLines={true}
        curveType="monotone"
      />
    </Card>
  );
};

const SalesTrendBarChart = () => {
  const { DealershipID } = useContext(GlobalContext);
  const [months, setMonths] = useState(1);
  const [data, setChartData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const fetchData = async () => {
    try {
      const response = await fetch("https://api.autodealerug.com/getsalestrend", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({
          DealershipID,
          months,
        })
      });
      const result = await response.json();
      console.log("Sales: ", result.data);
      const transformedData = result.data.map((item) => ({
        WeekStart: item.WeekStart,
        Local: Number(item.Local_Cash) + Number(item.Local_Credit),
        Imported: Number(item.Imported_Cash) + Number(item.Imported_Credit),
        Cash: Number(item.Local_Cash) + Number(item.Imported_Cash),
        Credit: Number(item.Local_Credit) + Number(item.Imported_Credit),
      }));

      setChartData(transformedData);
    } catch (err) {
      console.error("Error fetching stock data:", err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [months]);

  return (
    <Card>
      <div className="flex justify-between items-center">
        <Title className="font-medium flex-1">Weekly Sales</Title>
        <Select
          value={months}
          onValueChange={(value) => setMonths(Number(value))}
          className="flex-1"
        >
          {[...Array(6)].map((_, i) => (
            <SelectItem key={i + 1} value={i + 1}>
              {`Last ${i + 1} ${i === 0 ? "month" : "months"}`}
            </SelectItem>
          ))}
        </Select>
      </div>
      <TabGroup>
        <TabList className="mt-2">
          <Tab onClick={() => setSelectedTab(0)}>By Cash/Credit</Tab>
          <Tab onClick={() => setSelectedTab(1)}>By Local/Imported</Tab>
        </TabList>
        <TabPanels>
          <TabPanel className="h-[160px]">
            <BarChart
              data={data}
              index="WeekStart"
              categories={["Cash", "Credit"]}
              colors={["blue", "green"]}
              yAxisLabel="Number of Sales"
              stack={true}
              className="mt-2 h-full"
              yAxisWidth={20}
              showAnimation={true}
              showGridLines={true}
            />
          </TabPanel>
          <TabPanel className="h-[160px]">
            <BarChart
              data={data}
              index="WeekStart"
              categories={["Local", "Imported"]}
              colors={["red", "yellow"]}
              yAxisLabel="Number of Sales"
              stack={true}
              className="mt-2 h-full"
              yAxisWidth={20}
              showAnimation={true}
              showGridLines={true}
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </Card>
  );
};

const OutstandingCreditChart = () => {
  const [chartData, setChartData] = useState([]);
  const [daysBack, setDaysBack] = useState(90); // Default: Last 90 days
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [view, setView] = useState('outstanding'); // Default view: outstanding (alternatives: overdue, percentage)
  const { DealershipID } = useContext(GlobalContext);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch("https://api.autodealerug.com/getcredittrend", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({
          DealershipID,
          daysBack,
        })
      });

      if (!response.ok) {
        throw new Error(`Server responded with status: ${response.status}`);
      }

      const result = await response.json();

      // Format the data for the chart
      const formattedData = result.data.map((item, index) => {
        const date = new Date(item.date);

        // Calculate daily changes
        const dailyOutstandingChange = index > 0
          ? parseFloat(item.outstanding_credit_ugx) - parseFloat(result.data[index - 1].outstanding_credit_ugx)
          : 0;

        const dailyOverdueChange = index > 0
          ? parseFloat(item.overdue_credit_ugx) - parseFloat(result.data[index - 1].overdue_credit_ugx)
          : 0;

        return {
          date: date.toLocaleDateString("en-GB"),
          "Outstanding Credit": parseFloat(item.outstanding_credit_ugx),
          "Overdue Credit": parseFloat(item.overdue_credit_ugx),
          "Overdue Percentage": parseFloat(item.overdue_percentage),
          "Daily Outstanding Change": dailyOutstandingChange,
          "Daily Overdue Change": dailyOverdueChange
        };
      });

      setChartData(formattedData);
    } catch (err) {
      console.error("Error fetching credit data:", err);
      setError("Failed to load credit data");
    } finally {
      setIsLoading(false);
    }
  };
  console.log(view);
  useEffect(() => {
    fetchData();
  }, [daysBack, DealershipID]);

  // Format UGX values for display
  const formatUGX = (value) => {
    if (value >= 1_000_000) {
      return `${(value / 1_000_000).toFixed(1)}M`;
    } else if (value >= 1_000) {
      return `${(value / 1_000).toFixed(1)}K`;
    } else {
      return `${value.toLocaleString()}`;
    }
  };

  // Format percentage values
  const formatPercentage = (value) => {
    return `${value.toFixed(2)}%`;
  };

  // Determine which data to display based on the selected view
  const getChartCategories = () => {
    switch (view) {
      case 'outstanding':
        return ["Outstanding Credit"];
      case 'overdue':
        return ["Outstanding Credit", "Overdue Credit"];
      case 'percentage':
        return ["Overdue Percentage"];
      case 'daily':
        return ["Daily Outstanding Change", "Daily Overdue Change"];
      default:
        return ["Outstanding Credit"];
    }
  };

  // Set colors based on view
  const getChartColors = () => {
    switch (view) {
      case 'outstanding':
        return ["indigo"];
      case 'overdue':
        return ["blue", "red"];
      case 'percentage':
        return ["amber"];
      case 'daily':
        return ["blue", "red"];
      default:
        return ["indigo"];
    }
  };

  // Get the appropriate value formatter based on the view
  const getValueFormatter = () => {
    if (view === 'percentage') {
      return formatPercentage;
    }
    return formatUGX;
  };

  return (
    <Card className="min-w-[450px]">
      <div className="flex justify-between items-center mb-2">
        <Title className="text-lg font-medium">Credit Exposure</Title>
        <div className="flex space-x-2">
          <Select value={daysBack.toString()} onValueChange={(value) => setDaysBack(Number(value))}>
            {[30, 60, 90, 180, 365].map((days) => (
              <SelectItem key={days} value={days.toString()}>
                Last {days} Days
              </SelectItem>
            ))}
          </Select>
        </div>
      </div>

      <TabGroup className="mt-2" value={view}>
        <TabList variant="solid">
          <Tab value="outstanding" onClick={() => setView("outstanding")}>Outstanding</Tab>
          <Tab value="overdue" onClick={() => setView("overdue")}>Overdue</Tab>
          <Tab value="percentage" onClick={() => setView("percentage")}>Overdue %</Tab>
          <Tab value="daily" onClick={() => setView("daily")}>Daily Change</Tab>
        </TabList>
      </TabGroup>

      {isLoading ? (
        <div className="h-[210px] flex items-center justify-center">
          <Text>Loading credit data...</Text>
        </div>
      ) : error ? (
        <div className="h-[210px] flex items-center justify-center">
          <Text color="red">{error}</Text>
        </div>
      ) : (
        <AreaChart
          className="h-[210px] mt-2"
          data={chartData}
          index="date"
          categories={getChartCategories()}
          colors={getChartColors()}
          yAxisWidth={60}
          showLegend={true}
          showAnimation={true}
          showGridLines={true}
          curveType="monotone"
          valueFormatter={getValueFormatter()}
        />
      )}

      {!isLoading && !error && chartData.length > 0 && (
        <div className="mt-2 grid grid-cols-2 gap-4">
          <div className="bg-gray-50 p-3 rounded-md">
            <Text className="text-sm text-gray-500">Current Outstanding</Text>
            <Text className="text-lg font-medium">
              {formatUGX(chartData[chartData.length - 1]["Outstanding Credit"])}
            </Text>
          </div>
          <div className="bg-gray-50 p-3 rounded-md">
            <Text className="text-sm text-gray-500">Current Overdue</Text>
            <Text className="text-lg font-medium text-red-600">
              {formatUGX(chartData[chartData.length - 1]["Overdue Credit"])}
              <span className="text-sm ml-1 text-amber-600">
                ({chartData[chartData.length - 1]["Overdue Percentage"].toFixed(1)}%)
              </span>
            </Text>
          </div>
        </div>
      )}

      <Text className="mt-2 text-sm text-gray-500">
        {view === 'outstanding' && 'Total outstanding credit balance over time (UGX)'}
        {view === 'overdue' && 'Comparison of overdue vs total outstanding credit (UGX)'}
        {view === 'percentage' && 'Percentage of credit that is overdue (UGX)'}
        {view === 'daily' && 'Daily changes in outstanding and overdue credit amounts (UGX)'}
      </Text>
    </Card>
  );
};

/*
const CreditCollectionDashboard = () => {
  /* Sample data - replace with your actual data from the query
  const data = [
    { month: '2024-10', TotalTarget: 25000, OverdueTarget: 8000, ActualCollected: 20000 },
    { month: '2024-11', TotalTarget: 28000, OverdueTarget: 10000, ActualCollected: 19000 },
    { month: '2024-12', TotalTarget: 30000, OverdueTarget: 12000, ActualCollected: 22000 },
    { month: '2025-01', TotalTarget: 32000, OverdueTarget: 9000, ActualCollected: 30000 },
    { month: '2025-02', TotalTarget: 35000, OverdueTarget: 11000, ActualCollected: 25000 },
    { month: '2025-03', TotalTarget: 38000, OverdueTarget: 15000, ActualCollected: 28000 },
  ];
  const { DealershipID } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [months, setMonths] = useState(6);
  const [selectedMonth, setSelectedMonth] = useState(null);

  //Actual data fetch
  const fetchData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch("https://api.autodealerug.com/getcreditcollectiontrend", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({ DealershipID, months })
      });

      if (!response.ok) {
        throw new Error(`Server responded with status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result.data);
      setData(result.data);
      if (result.data.length > 0) {
        setSelectedMonth(result.data[result.data.length - 1].month);
      }
    } catch (err) {
      console.error("Error fetching credit data:", err);
      setError("Failed to load credit data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [months]);

  // Calculate completion percentages and add formatted month names
  const enhancedData = data.map(item => {
    const completionRate = item.ActualCollected / item.TotalTarget * 100;
    const dateObj = new Date(item.month + '-01');
    const formattedMonth = dateObj.toLocaleString('default', { month: 'short', year: '2-digit' });
    
    return {
      ...item,
      formattedMonth,
      completionRate: Math.round(completionRate),
      remainingTarget: Math.max(0, item.TotalTarget - item.ActualCollected),
      nonOverdueTarget: Math.max(0, item.TotalTarget - item.OverdueTarget)
    };
  });

  // Prepare data for stacked bar chart
  const stackedBarData = enhancedData.map(item => ({
    month: item.formattedMonth,
    'Non-Overdue Target': item.TotalTarget - item.OverdueTarget,
    'Overdue Target': item.OverdueTarget,
    'Collected': item.ActualCollected
  }));

  const selectedMonthData = enhancedData.find(item => item.month === selectedMonth) || {};
  
  // Prepare data for the donut chart
  const donutData = [
    { name: 'Collected', value: selectedMonthData.ActualCollected },
    { name: 'Remaining', value: selectedMonthData.remainingTarget }
  ];

  // Color schemes - using Tremor's color palette
  const colors = {
    collected: 'emerald',
    remaining: 'slate',
    nonOverdue: 'blue', 
    overdue: 'rose'
  };

  // For the target breakdown donut chart
  const targetBreakdownData = [
    { name: 'Non-Overdue', value: selectedMonthData.TotalTarget - selectedMonthData.OverdueTarget },
    { name: 'Overdue', value: selectedMonthData.OverdueTarget }
  ];

  return (
    <div className="space-y-6">
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <Text>Loading data...</Text>
        </div>
      ) : error ? (
        <div className="flex justify-center items-center h-64">
          <Text color="red">{error}</Text>
        </div>
      ) : (
        <>
          <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
            <Card decoration="top" decorationColor="blue">
              <Title>Monthly Collection Target</Title>
              <Text className="text-3xl font-bold mt-2">
                ${selectedMonthData.TotalTarget ? selectedMonthData.TotalTarget.toLocaleString() : 'N/A'}
              </Text>
              <Text className="text-sm text-gray-500">
                For {selectedMonthData.formattedMonth}
              </Text>
            </Card>
            
            <Card decoration="top" decorationColor="emerald">
              <Title>Amount Collected</Title>
              <Text className="text-3xl font-bold mt-2">
                ${selectedMonthData.ActualCollected ? selectedMonthData.ActualCollected.toLocaleString() : 'N/A'}
              </Text>
              <Text className="text-sm text-gray-500">
                {selectedMonthData.completionRate ? `${selectedMonthData.completionRate}% of target` : 'N/A'}
              </Text>
            </Card>
            
            <Card decoration="top" decorationColor="rose">
              <Title>Overdue Amount</Title>
              <Text className="text-3xl font-bold mt-2">
                ${selectedMonthData.OverdueTarget ? selectedMonthData.OverdueTarget.toLocaleString() : 'N/A'}
              </Text>
              <Text className="text-sm text-gray-500">
                {selectedMonthData.OverdueTarget && selectedMonthData.TotalTarget ? `${Math.round(selectedMonthData.OverdueTarget / selectedMonthData.TotalTarget * 100)}% of target` : 'N/A'}
              </Text>
            </Card>
          </div>
          
          <div className="grid gap-4 md:grid-cols-2">
            <Card>
              <div className="flex justify-between items-center mb-4">
                <Title>Monthly Completion Rate</Title>
                <div className="z-10">
                  <Select value={selectedMonth} onValueChange={setSelectedMonth}>
                    {enhancedData.map((item) => (
                      <SelectItem key={item.month} value={item.month}>
                        {item.formattedMonth}
                      </SelectItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <div className="h-56 w-56">
                  <DonutChart
                    data={donutData}
                    category="value"
                    index="name"
                    valueFormatter={(number) => `$${number.toLocaleString()}`}
                    colors={[colors.collected, colors.remaining]}
                    showLabel={true}
                    label={`${selectedMonthData.completionRate}%`}
                    className="mt-6"
                  />
                  <Legend
                    categories={['Collected', 'Remaining']}
                    colors={[colors.collected, colors.remaining]}
                    className="mt-3 justify-center"
                  />
                </div>
              </div>
            </Card>
            
            <Card>
              <Card>
                <TabGroup>
                  <div className="flex justify-between items-center">
                    <Title>Target Breakdown</Title>
                    <TabList className="mt-2" variant="solid">
                      <Tab>Donut</Tab>
                      <Tab>Trend</Tab>
                    </TabList>
                  </div>
                  <TabPanels>
                    <TabPanel>
                      <div className="flex justify-center mt-4">
                        <div className="h-56 w-56">
                          <DonutChart
                            data={targetBreakdownData}
                            category="value"
                            index="name"
                            valueFormatter={(number) => `$${number.toLocaleString()}`}
                            colors={[colors.nonOverdue, colors.overdue]}
                            showLabel={false}
                            className="mt-6"
                          />
                          <Legend
                            categories={['Non-Overdue', 'Overdue']}
                            colors={[colors.nonOverdue, colors.overdue]}
                            className="mt-3 justify-center"
                          />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <BarChart
                        data={enhancedData}
                        index="formattedMonth"
                        categories={['OverdueTarget', 'nonOverdueTarget']}
                        colors={[colors.overdue, colors.nonOverdue]}
                        stack={true}
                        valueFormatter={(number) => `$${number.toLocaleString()}`}
                        className="h-64 mt-6"
                      />
                      <Legend
                        categories={['Overdue', 'Non-Overdue']}
                        colors={[colors.overdue, colors.nonOverdue]}
                        className="mt-3 justify-center"
                      />
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
              </Card>
            </Card>
          </div>
          
          <Card>
            <Title>Monthly Collection Performance</Title>
            <Text>Stacked view showing overdue and non-overdue targets with actual collection</Text>
            <BarChart
              data={stackedBarData}
              index="month"
              categories={['Non-Overdue Target', 'Overdue Target', 'Collected']}
              colors={[colors.nonOverdue, colors.overdue, colors.collected]}
              valueFormatter={(number) => `$${number.toLocaleString()}`}
              className="h-72 mt-6"
              layout="vertical"
            />
            <Legend
              categories={['Non-Overdue Target', 'Overdue Target', 'Collected']}
              colors={[colors.nonOverdue, colors.overdue, colors.collected]}
              className="mt-3"
            />
          </Card>
        </>
      )}
    </div>
  );
}; */


// Sample data (replace with your actual data)
const sampleData = [
    { month: '2024-11', DueTarget: 9500000.00, OverdueTarget: 7500000.00, DueCollected: 0.00, OverdueCollected: 900000.00 },
    { month: '2024-12', DueTarget: 6500000.00, OverdueTarget: 10500000.00, DueCollected: 0.00, OverdueCollected: 0.00 },
    { month: '2025-01', DueTarget: 6500000.00, OverdueTarget: 6500000.00, DueCollected: 0.00, OverdueCollected: 4000000.00 },
    { month: '2025-02', DueTarget: 6500000.00, OverdueTarget: 6500000.00, DueCollected: 0.00, OverdueCollected: 0.00 },
    { month: '2025-03', DueTarget: 6500000.00, OverdueTarget: 6500000.00, DueCollected: 3600000.00, OverdueCollected: 0.00 },
    { month: '2025-04', DueTarget: 6500000.00, OverdueTarget: 6500000.00, DueCollected: 0.00, OverdueCollected: 0.00 },
];

// Helper function to format currency (UGX)
const formatUGX = (value) => {
    if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
        return (value / 1000).toFixed(1) + 'K';
    } else {
        return value.toFixed(2);
    }
};

// Custom MetricCard component using standard Tremor components
const MetricCard = ({ title, value, delta, textClassName, className }) => {
    return (
        <Card className={className}>
            <Text>{title}</Text>
            <Metric>{value}</Metric>
            {delta && <Text className={textClassName}>{delta}</Text>}
        </Card>
    );
};

// Custom CardHeader and CardTitle for compatibility
const CardHeader = ({ children, className }) => (
    <div className={`p-6 pb-0 ${className || ''}`}>{children}</div>
);

const CardTitle = ({ children, className }) => (
    <h3 className={`text-lg font-medium ${className || ''}`}>{children}</h3>
);

// Custom CardContent for compatibility
const CardContent = ({ children, className }) => (
    <div className={`p-6 ${className || ''}`}>{children}</div>
);

const CreditCollectionOverview = () => {
    // Calculate KPIs for the current month (using the last entry in the data)
    const currentMonthData = sampleData[sampleData.length - 1];
    const lastMonthData = sampleData[sampleData.length-2] || currentMonthData;

    const currentDueCollected = currentMonthData.DueCollected;
    const currentOverdueCollected = currentMonthData.OverdueCollected;
    const currentDueTarget = currentMonthData.DueTarget;
    const currentOverdueTarget = currentMonthData.OverdueTarget;

    const lastDueCollected = lastMonthData.DueCollected;
    const lastOverdueCollected = lastMonthData.OverdueCollected;

    // Add check to prevent division by zero
    const dueCollectedDelta = lastDueCollected === 0 ? 0 : ((currentDueCollected - lastDueCollected) / lastDueCollected) * 100;
    const overdueCollectedDelta = lastOverdueCollected === 0 ? 0 : ((currentOverdueCollected - lastOverdueCollected) / lastOverdueCollected) * 100;

    
// Transform data for side-by-side stacked bars
const transformDataForSideBySideBars = () => {
  const transformedData = [];
  
  // Create two entries for each month
  sampleData.forEach(item => {
    // First entry: Targets
    transformedData.push({
      category: `${item.month} Targets`,
      Due: item.DueTarget,
      Overdue: item.OverdueTarget,
      type: 'Target'
    });
    
    // Second entry: Collections
    transformedData.push({
      category: `${item.month} Collected`,
      Due: item.DueCollected,
      Overdue: item.OverdueCollected,
      type: 'Collected'
    });
  });
  
  return transformedData;
};

    return (
        <div className="p-4 space-y-8 bg-gray-50">
            <h1 className="text-2xl font-bold text-gray-800">Financial Performance Overview</h1>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* KPIs */}
                <Card className="bg-white border-gray-200 shadow-lg">
                    <CardHeader>
                        <CardTitle className="text-gray-800">Key Performance Indicators</CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-4">
                        <Flex className="space-x-4">
                            <MetricCard
                                title="Due Collected"
                                value={`UGX ${formatUGX(currentDueCollected)}`}
                                delta={`${dueCollectedDelta > 0 ? '+' : ''}${dueCollectedDelta.toFixed(2)}% from last month`}
                                textClassName={dueCollectedDelta > 0 ? "text-green-500" : "text-red-500"}
                                className="bg-gray-50 border-gray-200"
                            />
                            <MetricCard
                                title="Overdue Collected"
                                value={`UGX ${formatUGX(currentOverdueCollected)}`}
                                delta={`${overdueCollectedDelta > 0 ? '+' : ''}${overdueCollectedDelta.toFixed(2)}% from last month`}
                                textClassName={overdueCollectedDelta > 0 ? "text-green-500" : "text-red-500"}
                                className="bg-gray-50 border-gray-200"
                            />
                        </Flex>
                        <Flex className="space-x-4">
                            <MetricCard
                                title="Due Target"
                                value={`UGX ${formatUGX(currentDueTarget)}`}
                                className="bg-gray-50 border-gray-200"
                            />
                            <MetricCard
                                title="Overdue Target"
                                value={`UGX ${formatUGX(currentOverdueTarget)}`}
                                className="bg-gray-50 border-gray-200"
                            />
                        </Flex>
                    </CardContent>
                </Card>

                {/* Time Series Chart */}
                <Card className="bg-white border-gray-200 shadow-lg">
                    <CardHeader>
                        <CardTitle className="text-gray-800">Monthly Trend</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <LineChart
                            data={sampleData}
                            index="month"
                            categories={['DueTarget', 'OverdueTarget', 'DueCollected', 'OverdueCollected']}
                            colors={['emerald', 'rose', 'blue', 'amber']}
                            valueFormatter={formatUGX}
                            className="h-72"
                            showLegend={true}
                            showGridLines={true}
                        />
                    </CardContent>
                </Card>
            </div>

            {/* Composition Chart */}
            <Card className="bg-white border-gray-200 shadow-lg">
                <CardHeader>
                    <CardTitle className="text-gray-800">Collection Progress</CardTitle>
                </CardHeader>
                <CardContent>
                <BarChart
                  data={transformDataForSideBySideBars()}
                  index="category"
                  categories={["Due", "Overdue"]}
                  colors={["blue", "indigo"]}
                  valueFormatter={formatUGX}
                  className="h-72"
                  stack={true}
                  showLegend={true}
                />
                </CardContent>
            </Card>

            {/* Data Table */}
            <Card className="bg-white border-gray-200 shadow-lg">
                <CardHeader>
                    <CardTitle className="text-gray-800">Data Table</CardTitle>
                </CardHeader>
                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell className="text-gray-600">Month</TableHeaderCell>
                                <TableHeaderCell className="text-gray-600">Due Target (UGX)</TableHeaderCell>
                                <TableHeaderCell className="text-gray-600">Overdue Target (UGX)</TableHeaderCell>
                                <TableHeaderCell className="text-gray-600">Due Collected (UGX)</TableHeaderCell>
                                <TableHeaderCell className="text-gray-600">Overdue Collected (UGX)</TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sampleData.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell className="text-gray-700">{item.month}</TableCell>
                                    <TableCell className="text-gray-700">{formatUGX(item.DueTarget)}</TableCell>
                                    <TableCell className="text-gray-700">{formatUGX(item.OverdueTarget)}</TableCell>
                                    <TableCell className="text-gray-700">{formatUGX(item.DueCollected)}</TableCell>
                                    <TableCell className="text-gray-700">{formatUGX(item.OverdueCollected)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </div>
    );
};



