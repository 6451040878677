import React, { useState, useContext } from 'react';
import fields from './fields';
import { GlobalContext } from "./Cars";
import { Divider, TextInput } from '@tremor/react';

const Logo = (props) => (
  <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    <path d="M10.9999 2.04938L11 5.07088C7.6077 5.55612 5 8.47352 5 12C5 15.866 8.13401 19 12 19C13.5723 19 15.0236 18.4816 16.1922 17.6064L18.3289 19.7428C16.605 21.1536 14.4014 22 12 22C6.47715 22 2 17.5228 2 12C2 6.81468 5.94662 2.55115 10.9999 2.04938ZM21.9506 13.0001C21.7509 15.0111 20.9555 16.8468 19.7433 18.3283L17.6064 16.1922C18.2926 15.2759 18.7595 14.1859 18.9291 13L21.9506 13.0001ZM13.0011 2.04948C17.725 2.51902 21.4815 6.27589 21.9506 10.9999L18.9291 10.9998C18.4905 7.93452 16.0661 5.50992 13.001 5.07103L13.0011 2.04948Z" />
  </svg>
);


function LogIn({ setLogInStatus, setUserData }){
    const { toggleMessageBox } = useContext(GlobalContext);
    const [creds, setCreds] = useState({
        UserName: '',
        Password: ''
    });
    const [errors, setErrors] = useState({
        UserName: '',
        Password: ''
    });
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = ({ target }) => {
        setCreds(prev => ({
            ...prev, [target.name]: target.value
        }));
    };

    const validateInputs = ()=>{
        const errors = { UserName: "", Password: ""};
        
        //Validate UserName using regex
        const regex = new RegExp(fields.UserName.regex)
        if(!regex.test(creds.UserName)) errors.UserName = "Invalid Username";

        //Check they're not empty
        if(!creds.UserName) errors.UserName = "Username can't be empty";
        if(!creds.Password) errors.Password ="Please enter a password";

        setErrors(errors); //Set errors. If empty, set empty.

        if(errors.UserName || errors.Password){ //We have one or both errors
            return false;
        }
        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        //Validate credentials
        console.log("Attempting submit");
        if(!validateInputs()) return;
        console.log("Submitting");
        // Submit. If Ok, open CarsNav
        fetch('https://api.autodealerug.com/login', {
            method: "POST",
            headers: {
                "Content-Type": "Application/Json"
            },
            credentials: "include",
            body: JSON.stringify(creds)
        }).then(async (response)=>{
            const resObj = await response.json();
            console.log(`Cookie in login: ${document.cookie}`);
            console.log(`Cookie in header: ${response.headers.get("Set-Cookie")}`);
            if(!response.ok){//Some kind of db error
                toggleMessageBox({
                    on: true,
                    title: "Database error! Unable to login",
                    message: "Server error: "+resObj.message,
                    buttons: ["OK"],
                    clicked: ""
                });
            } else { //Error code other than 5xx
                if(resObj.success!==true){ //Wrong username or password
                    setErrors({
                      UserName: resObj?.message || "username or password is wrong",
                      Password: resObj?.message || "username or password is wrong"
                    });
                    return;
                } else { //Success!
                    console.log("Success!");
                    setLogInStatus(true);
                    //Set the user details. Parse the JSON In preferences
                    //If prefs is null, pass an empty object
                    const prefs = resObj.userData.Preferences || {};
                    for(let sheetname of Object.keys(prefs)){
                        prefs[sheetname] = JSON.parse(prefs[sheetname]);
                    } 
                    resObj.userData.Preferences = prefs;
                    //console.log(resObj.userData.Preferences);
                    setUserData(resObj.userData);
                }
            }
        }).catch((err)=>{
            //Network error or something
            console.log(err);
            toggleMessageBox({
                on: true,
                title: "Unable To Login",
                message: "Unable to connect to the server: Please check your internet and try again.",
                buttons: ["OK"],
                clicked: ""
            });
        });
    };

    const handleBlur = ({ target })=>{
        validateInputs();
    }

    return (
        
       /* <div className='login'>
            <div className='top'>
                <div className='input-pair'>
                    <label htmlFor="username">Username:</label>
                    <input className='input' id="username" name="UserName" type="text" value={creds.UserName} onChange={handleChange} onBlur={handleBlur}></input>
                    {errors['UserName'] && <p className='input-errors'>{errors['UserName']}</p>}
                </div>

                <div className='input-pair'>
                    <label htmlFor="password">Password:</label>
                    <input className='input' id="password" name="Password" type={showPassword ? "text" : "password"} value={creds.Password} onChange={handleChange} onBlur={handleBlur}></input>
                    {errors['Password'] && <p className='input-errors'>{errors['Password']}</p>}
                </div>

                <div className='input-pair checkbox'>
                    <label htmlFor="showPassword">Show Password</label>
                    <input type="checkbox" id="showPassword" checked={showPassword} onChange={() => setShowPassword(!showPassword)} />
                </div>

                <div className='forgot-password'>
                    <a href="#">Forgotten password?</a>
                </div>

                <div className='buttons-container'>
                    <button className='btn' onClick={()=>setLogInStatus("register")}>Register</button>
                    <button className='btn' onClick={handleSubmit}>Login</button>
                </div>
            </div>
        </div>*/

        <>
      <div className="flex min-h-screen flex-1 flex-col justify-center px-4 py-10 lg:px-6">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex items-center space-x-2.5">
            <Logo
              className="h-7 w-7 text-tremor-content-strong dark:text-dark-tremor-content-strong"
              aria-hidden={true}
            />
            <p className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Welcome to Autodealer Ug
            </p>
          </div>
          <h3 className="mt-6 text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
            Sign in to your account
          </h3>
          <p className="mt-2 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
            Don't have an account?{' '}
            <a
              href="#"
              className="font-medium text-tremor-brand hover:text-tremor-brand-emphasis dark:text-dark-tremor-brand hover:dark:text-dark-tremor-brand-emphasis"
              onClick={()=>setLogInStatus("register")}
            >
              Sign up
            </a>
          </p>
          <form action="#" method="post" className="mt-6 space-y-4">
            <div>
              <label
                htmlFor="UserName"
                className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
              >
                Username
                {errors['UserName'] && <p className='input-errors'>{errors['UserName']}</p>}
              </label>
              <TextInput
                type="text"
                id="UserName"
                name="UserName"
                placeholder="sarahconnor21"
                className="mt-2"
                value={creds.UserName} 
                onChange={handleChange} 
                onBlur={handleBlur}
              />
            </div>
            <div>
              <label
                htmlFor="Password"
                className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
              >
                Password
                {errors['Password'] && <p className='input-errors'>{errors['Password']}</p>}
              </label>
              <TextInput
                type="password"
                id="Password"
                name="Password"
                autoComplete="password"
                placeholder="Password"
                className="mt-2"
                value={creds.Password} 
                onChange={handleChange} 
                onBlur={handleBlur}
              />
            </div>
            <button
                className="mt-4 w-full whitespace-nowrap rounded-tremor-default bg-tremor-brand py-2 text-center text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis dark:bg-dark-tremor-brand dark:text-dark-tremor-brand-inverted dark:shadow-dark-tremor-input dark:hover:bg-dark-tremor-brand-emphasis"
                onClick={handleSubmit}
            >
              Sign in
            </button>
          </form>
          <p className="mt-6 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
            Forgot your password?{' '}
            <a
              href="#"
              className="font-medium text-tremor-brand hover:text-tremor-brand-emphasis dark:text-dark-tremor-brand hover:dark:text-dark-tremor-brand-emphasis"
            >
              Reset password
            </a>
          </p>
        </div>
      </div>
    </>

    );
}

export default LogIn;